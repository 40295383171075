/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';

import {
    NavLink,
    useRouteMatch,
    useHistory
} from "react-router-dom";

import footerIcon from '../../../../assets/images/sidebar-foot.png';
import { LogoutOutlined } from '@ant-design/icons';
import DefaultAvatar from '../../../../assets/images/defaultAvatar.jpeg';
import { GET, POST } from '../../../../apiClient';
import { adminLogout } from '../../../../globalConstant';
import { notification} from "antd";
import { useAuth } from "../../../../Provider/AuthProvider";


const SideBar = (props) => {
    let { url } = useRouteMatch();
    let auth = useAuth();

    const dispatch = useDispatch();
    let history = useHistory();
    const [isCollapse, setCollapse] = useState(false);
    const [isUserRole, setUserRole] = useState('');

    // let user = useSelector((state) => state.studentProfileReducer);
    // console.log(`-------------------------------------`,user);
    const toggleMenu = () => {
        props.toggleMenu();
        setCollapse(!isCollapse)
    }

    const logoutHandler = () => {

        GET(adminLogout).then(res => {
            if(res.data.data.success == 1){
            notification.success({ message: 'Success' });
            dispatch({ type: 'LOGOUT' });
            auth.setUserState('');
            }
            else{
            notification.error({ message: 'Error' });

            }
        });
    }




    return (
        <>
            <div className="sidebar-menu" className={(isCollapse) ? "sidebar-menu closesidebar" : "sidebar-menu"}>
                <div className="overflow">
                    <div className="user-avatar">
                        <div className="avatar">
                            <img src={DefaultAvatar} alt="avatar" />
                        </div>
                        {/* <h6>{user.firstName} &nbsp; {user.lastName}</h6> */}
                        <h6>Super Admin</h6>
                    </div>
                    <div className="actionbtn" onClick={() => toggleMenu()}>
                    <span></span>
                    </div>
                    <menu id="nav">
                        <ul>
                            {/* <li className="dashboard">
                                <NavLink title="Dashboard" to={`${url}`} exact activeClassName="active dashboard" >
                                    <i></i> 
                                    <span>Dashboard</span>
                                </NavLink>
                            </li> */}


                            <li className="availability"><NavLink title="Availability" to={`${url}/tutors`}  activeClassName="active availability" ><i></i> <span>Tutors</span></NavLink></li>
                            <li className="quran"><NavLink title="Quran Revision" to={`${url}/students`}  activeClassName="active quran" ><i></i> <span>Students</span></NavLink></li>
                             <li className="messages"><NavLink title="Messages" to={`${url}/billing`} exact activeClassName="active messages" ><i></i> <span>Billing</span></NavLink></li>
                             <li className="messages"><NavLink title="Messages" to={`${url}/payouts`} exact activeClassName="active messages" ><i></i> <span>Payouts</span></NavLink></li>
                             <li className="messages"><NavLink title="Messages" to={`${url}/trials`} exact activeClassName="active messages" ><i></i> <span>Trials</span></NavLink></li>
                             <li className="setting"><NavLink title="Configuration" to={`${url}/configuration`} exact activeClassName="active setting" ><i></i> <span>Configurations</span></NavLink></li>
                            {/* <li className="edit"><NavLink title="Edit Profile" to={`${url}/profile`} exact activeClassName="active edit" ><i></i> <span>Edit Profile</span></NavLink></li>  */}
                            <li className="logout" onClick={logoutHandler}><i className=" fa-power-off fa-2x  fa"></i> <p> Logout</p></li>





                        </ul>
                    </menu>
                    {/* <div className="helpsupport">
                        <a href="/" title="Need Help & Support?">
                            <div className="helpsupprt-img">
                                <img src={footerIcon} alt="" />
                            </div>
                            <h6>Need HElp & SUPPORT?</h6>
                        </a>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default SideBar
